import React from "react";
import logo from "../assets/logo.png";
import { BsTwitter } from "react-icons/bs";
import { FaTiktok, FaDiscord } from "react-icons/fa";
export default function Footer() {
  // const links = [
  //   {
  //     title: "About",
  //     data: ["The Team", "The Mystic Path", "The Lore"],
  //   },
  //   {
  //     title: "Our NFTs",
  //     data: ["JPG.store", "Coming Soon"],
  //   },
  //   {
  //     title: "Contact",
  //     data: ["Email"],
  //   },
  //   {
  //     title: "Social",
  //     data: ["Twitter", "Discord"],
  //   },
  // ];
  const links = [
    {
      title: "About",
      data: [
        { label: "The Team", url: "#team" },
        { label: "The Mystic Path", url: "#roadmap" },
        { label: "The Lore", url: "#lore" },
        { label: "FAQ", url: "#faq" },
      ],
    },
    {
      title: "Our NFTs",
      data: [
        // { label: "JPG.store", url: "https://jpg.store" },
        {
          label: "Book of Resurrection",
          url: "https://www.jpg.store/collection/zalathorbookofresurrection",
        },
        { label: "Sorcerers Matriculation", url: "/" },
      ],
    },
    {
      title: "Contact",
      data: [{ label: "Email", url: "mailto:info@zalathor.academy" }],
    },
    {
      title: "Social",
      data: [
        { label: "Twitter", url: "https://twitter.com/ZalathorAcademy" },
        { label: "Discord", url: "https://discord.gg/zalathoracademy" },
      ],
    },
  ];
  // const socialLink = [<BsTwitter />, <FaDiscord />];
  const socialLink = [
    {
      icon: <BsTwitter className="twitter-icon" />,
      url: "https://twitter.com/ZalathorAcademy",
    },
    {
      icon: <FaDiscord className="discord-icon" />,
      url: "https://discord.gg/zalathoracademy",
    },
  ];

  return (
    <footer>
      <div className="upper">
        <div className="brand-container">
          <p>Zalathor Academy of Sorcery</p>

          <ul>
            {socialLink.map(({ icon, url }, index) => (
              <li key={index}>
                <a href={url}>{icon}</a>
              </li>
            ))}
          </ul>
        </div>
        <div className="links--footer">
          {links.map(({ title, data }, index) => {
            return (
              <div className="link--footer" key={index}>
                <h4>{title}</h4>
                <ul>
                  {data.map(({ label, url }, index2) => (
                    <li key={index2}>
                      <a href={url}>{label} </a>
                    </li>
                  ))}
                </ul>
              </div>
            );
          })}
        </div>
      </div>
      <div className="lower">
        <span>&copy; Copyright 2024 </span>
        <span>Officially launched on April 2023</span>
      </div>
    </footer>
  );
}
