import React from "react";
import clients1 from "../assets/clients1.svg";
import clients2 from "../assets/clients2.webp";
import clients3 from "../assets/clients3.png";

export default function Clients() {
  const data = [clients1, clients2, clients3];
  const links = [
    "https://zealy.io",
    "https://www.jpg.store/collection/zalathorbookofresurrection",
    "https://blockinvestmentgroup.com",
  ];

  return (
    <div className="clients">
      <div className="container">
        <div className="clients-container">
          {data.map((client, index) => (
            <div
              className={`client ${index === 0 ? "first-client" : ""}`}
              key={index}
            >
              <a href={links[index]} target="_blank" rel="noopener noreferrer">
                <img src={client} alt="client" />
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
