import React, { useState, useEffect } from "react";
import Clients from "./components/Clients";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import ScrollToTop from "./components/ScrollToTop";
import Lore from "./components/Lore";
import Welcome from "./components/Welcome";
import RoadmapTEST from "./components/RoadmapTEST";
import Team from "./components/Team";
import scrollreveal from "scrollreveal";
// import scrollreveal from "scrollreveal";
import "./sass/index.scss";
import Faq from "./components/Faq";
function App() {
  const [theme, setTheme] = useState("dark");
  const changeTheme = () => {
    theme === "dark" ? setTheme("light") : setTheme("dark");
  };
  // useEffect(() => {
  //   const registerAnimations = () => {
  //     const sr = scrollreveal({
  //       origin: "top",
  //       distance: "20px",
  //       duration: 1000,
  //       reset: false,
  //     });
  //     sr.reveal(
  //       `
  //       nav,
  //       .signup,
  //       .lore,
  //       .roadmap,
  //       .super-rare,
  //       .like,
  //       .clients,
  //       .releases,
  //       footer
  //   `,
  //       {
  //         interval: 100,
  //       }
  //     );
  //   };
  //   registerAnimations();
  // }, []);
  // window.setTimeout(() => {
  //   const home = document.getElementsByClassName("home");
  //   home[0].style.transform = "none";
  //   const nav = document.getElementsByTagName("nav");
  //   nav[0].style.transform = "none";
  // }, 1500);

  //   return (
  //     <div data-theme={theme} className="bg-container">
  //       <ScrollToTop />
  //       <Navbar changeTheme={changeTheme} currentTheme={theme} />
  //       {/* <Home /> */}
  //       <div data-theme={theme} className="app-container">
  //         {/* <div className="welcome-container"> */}
  //         <Welcome />
  //         {/* </div> */}

  //         <Lore />
  //         <RoadmapTEST />
  //         <Team />
  //         <Faq />
  //         <Clients />
  //         <Footer />
  //       </div>
  //     </div>
  //   );
  // }

  // export default App;
  return (
    <div data-theme={theme} className="bg-container">
      {/* <ScrollToTop /> */}
      <div className="bg2">
        <Navbar changeTheme={changeTheme} currentTheme={theme} />
        {/* <div className="bg-overlay"></div> */}
        <Welcome />
      </div>
      <div data-theme={theme} className="app-container"></div>
      {/* </div> */}
      <Lore />
      <RoadmapTEST />
      <Team />
      <Faq />
      <Clients />
      <Footer />
    </div>
  );
}
export default App;
