import React, { useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import the carousel styles
import { Carousel } from "react-responsive-carousel"; // Import the Carousel component
import lore1 from "../assets/lore1.jpg";
import lore2 from "../assets/lore2.jpg";
import lore3 from "../assets/lore3.jpg";
import lore4 from "../assets/lore4.jpg";
import lore5 from "../assets/lore5.jpg";
import lore6 from "../assets/lore6.jpg";
import lore7 from "../assets/lore7.jpg";
import lore8 from "../assets/lore8.jpg";
import lore9 from "../assets/lore9.jpg";
import lore10 from "../assets/lore10.jpg";
import lore11 from "../assets/lore11.jpg";
import lore12 from "../assets/lore12.jpg";
import lore13 from "../assets/lore13.jpg";
import lore14 from "../assets/lore14.jpg";
import lore15 from "../assets/lore15.jpg";
import lore16 from "../assets/lore16.jpg";

// export default function Lore() {
//   return (
//     <div className="lore">
//       <div className="container">
//         <div id="lore" className="content">
//           <h1 className="title">The Chronicle</h1>

//           {/* <button>Sign Up</button> */}
//           <div className="image-container">
//             <div className="image">
//               <img src={lore1} alt="home image" />
//             </div>
//             <div className="ellipse-container">
//               <div className="ellipse pink"></div>
//               <div className="ellipse orange"></div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

export default function Lore() {
  const [modalImage, setModalImage] = useState(null);

  const openModal = (imageSrc, title) => {
    setModalImage(imageSrc, title);
  };

  const closeModal = () => {
    setModalImage(null);
  };
  return (
    <>
      <div className="lore">
        <div className="container">
          <div id="lore" className="content">
            {/* <button>Sign Up</button> */}
            <h1 className="title">The Chronicle</h1>
            <div className="carousel-container">
              <Carousel
                showThumbs={true}
                infiniteLoop
                autoPlay
                interval={200000}
                transitionTime={1500}
                showStatus={false}
                swipeable={false}
              >
                <div>
                  <img src={lore1} alt="lore1" />
                </div>
                <div>
                  <img src={lore2} alt="lore2" />
                </div>
                <div>
                  <img src={lore3} alt="lore3" />
                </div>
                <div>
                  <img src={lore4} alt="lore4" />
                </div>
                <div>
                  <img src={lore5} alt="lore5" />
                </div>
                <div>
                  <img src={lore6} alt="lore6" />
                </div>
                <div>
                  <img src={lore7} alt="lore7" />
                </div>
                <div>
                  <img src={lore8} alt="lore8" />
                </div>
                <div>
                  <img src={lore9} alt="lore9" />
                </div>
                <div>
                  <img src={lore10} alt="lore10" />
                </div>
                <div>
                  <img src={lore11} alt="lore11" />
                </div>
                <div>
                  <img src={lore12} alt="lore12" />
                </div>
                <div>
                  <img src={lore13} alt="lore13" />
                </div>
                <div>
                  <img src={lore14} alt="lore14" />
                </div>
                <div>
                  <img src={lore15} alt="lore15" />
                </div>
                <div>
                  <img src={lore16} alt="lore16" />
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
      <div className="title-mobile">
        {" "}
        <h1 className="tmb"> Chronicle </h1>
      </div>
      <div className="mobile-only">
        {[
          { imageSrc: lore1, title: "Chronicle 1" },
          { imageSrc: lore2, title: "Chronicle 2" },
          { imageSrc: lore3, title: "Chronicle 3" },
          { imageSrc: lore4, title: "Chronicle 4" },
          { imageSrc: lore5, title: "Chronicle 5" },
          { imageSrc: lore6, title: "Chronicle 6" },
          { imageSrc: lore7, title: "Chronicle 7" },
          { imageSrc: lore8, title: "Chronicle 8" },
          { imageSrc: lore9, title: "Chronicle 9" },
          { imageSrc: lore10, title: "Chronicle 10" },
          { imageSrc: lore11, title: "Chronicle 11" },
          { imageSrc: lore12, title: "Chronicle 12" },
          { imageSrc: lore13, title: "Chronicle 13" },
          { imageSrc: lore14, title: "Chronicle 14" },
          { imageSrc: lore15, title: "Chronicle 15" },
          { imageSrc: lore16, title: "Chronicle 16" },
        ].map((chronicle, index) => (
          <div
            className="image-cont"
            key={index}
            onClick={() => openModal(chronicle.imageSrc, chronicle.title)}
          >
            <p className="chronicle-title">{chronicle.title}</p>

            <img src={chronicle.imageSrc} alt={`lore${index + 1}`} />
          </div>
        ))}
      </div>

      {/* Modal */}
      {modalImage && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal">
            <p className="chronicle-title">{modalImage.title}</p>

            <img src={modalImage} alt="modal" />
          </div>
        </div>
      )}
    </>
  );
}
