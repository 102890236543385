import React, { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdClose } from "react-icons/md";
// import { ImSun } from "react-icons/im";
import logo from "../assets/logo.png";
// import { BsFillMoonFill } from "react-icons/bs";
export default function Navbar() {
  const [navState, setNavState] = useState(false);
  return (
    <nav>
      {/* <div className="brand-container">
        <div className="brand">
          <img loading="lazy" src={logo} alt="logo" />
        </div>

        <div className="toggle-container">
          <div className="toggle">
            {navState ? (
              <MdClose onClick={() => setNavState(false)} />
            ) : (
              <GiHamburgerMenu onClick={() => setNavState(true)} />
            )}
          </div>
        </div>
      </div>
      <div className={`links-container ${navState ? "nav-visible" : ""}`}>
        <ul className="links">
          <li>
            <a href="#lore">Chronicle</a>
          </li>
          <li>
            <a href="#roadmap">Mystic Path</a>
          </li>
          <li>
            <a href="#team">Team</a>
          </li>
          <li>
            <a href="#faq">FAQ</a>
          </li>
          <li>Connect Wallet (soon)</li>
        </ul>
      </div> */}
      <div className="brand">
        <img src={logo} alt={logo} width={350} height={250} />
        {/* <span>logo</span> */}
      </div>
      <div className="links-container">
        <ul className="links">
          <li className="link">
            <a href="#lore">Chronicle</a>
          </li>
          <li className="link">
            <a href="#roadmap">Mystic Path</a>
          </li>
          <li className="link">
            <a href="#team">Team</a>
          </li>
          <li className="link">
            <a href="#faq">FAQ</a>
          </li>
        </ul>
      </div>
    </nav>
  );
}
