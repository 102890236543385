import React from "react";
export default function Card({ image, name, title, department, tag, time }) {
  return (
    <div className="card">
      <div className="card-image">
        <img src={image} alt="super1" />
      </div>
      <div className="card-content">
        <div className="card-heading">
          <span className="card-name">{name}</span>
          <span className="card-top">Spell Mastered</span>
        </div>
        <div className="card-details">
          <h4 className="card-title">{title}</h4>
          <div
            className="card-department"
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              justifySelf: "center",
              alignSelf: "center",
              justifyItems: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                justifySelf: "center",
                alignSelf: "center",
                justifyItems: "center",
                alignItems: "center",
              }}
            >
              {department}
            </div>
          </div>
        </div>
        <div className="card-sub-details">
          <span>{tag}</span>
          <span>{time}</span>
        </div>
      </div>
    </div>
  );
}
