import React, { useState, useRef, useEffect } from "react";

export default function Faq() {
  const [activeIndex, setActiveIndex] = useState(null);
  const faqAnswersRef = useRef([]);

  const handleQuestionClick = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  useEffect(() => {
    faqAnswersRef.current.forEach((faqAnswerElement, index) => {
      faqAnswerElement.style.maxHeight =
        index === activeIndex ? `${faqAnswerElement.scrollHeight}px` : 0;
    });
  }, [activeIndex]);

  const faqItems = [
    {
      question: "What is the project about?",
      answer:
        "<br>Zalathor Academy of Sorcery is a multilevel, story-driven & art-based collectible project.",
    },
    {
      question: "What does the project offer in terms of utility?",
      answer:
        "<br>Our main focus is to bring utility to our community, as well as top quality, unique art.<br> The project is developed on 3 different type of pillars: <br><br> 1. The <strong>lore/story</strong> which will be driven by our holders as we move on. <br><br> 2. <strong>Art</strong>: We have taken the hard path and created unique, photorealistic <strong>Sorcerers</strong> figures for our main collection.<br>In addition we have created the <strong>Book of Resurrection</strong> which is a 3D, animated limited sub-collection. <br><br> 3. <strong>Utility</strong>: We plan to have different kinds of utilities for our holders: <br><br> • Customized tools & bots that will enable our holders to navigate the crypto & NFTs market more efficiently (floor price, NFT sniping, influencer tweets/follows, Sentiment bot, signal trading service, dynamic holders bot and more to come.) <br> • Native token & staking mechanism that will be connected with the Magic Duels (more details will follow as the project unfolds) <br> • As we move on, more projects will be able to join our platform, making the Zalathor Academy of Sorcery a cross-project ecosystem <br> • 3D printed materials: Selected long-term holders will receive 3D printed figures of ?!?!? 🤫 <br> • Holders will get the chance to participate in NFTs raffles & auctions that the project keeps in the Vault (community wallet). They will also get to vote for our future investments in terms of new CNFT purchases <br><br>  ...and more.",
    },
    {
      question: "Is your team doxxed?",
      answer: `<br>We have been officially doxxed by the Block Investment Group. <a href="/Certificate.png" className="view-certificate-button" target="_blank" rel="noopener noreferrer"> <strong>View Certificate</strong></a>`,
    },
    {
      question: "What is the Quest of Faith?",
      answer:
        "<br>The Quest of Faith has been designed by the High Mages to test the commitment, focus and endurance of the aspirant wizards. In order for candidates to successfully complete the Quest, they have to stay dedicated and claim as many tasks as possible. Enter the Athenaeum to begin.",
    },
    {
      question: "What is the Book of Resurrection?",
      answer:
        "<br>The Book of Resurrection is a 3D, animated limited NFT sub-collection. The Book is our version of a pass and only the members who meet specific criteria in our Discord server will be able to participate to the mint.",
    },
    {
      question: "Why do I need it?",
      answer:
        "<br>• 1 FREE Sorcerer at Sorcerer Matriculation (main mint) per Book<br><br>• WL spots for all future mints<br><br>• 3D print of the Book for selected long-term holders (after a series of snapshots)<br><br>• Access to the Hall of Mysteries (early use of customized bots)<br><br>• Staking availability to collaborating projects<br><br>• $ENIGMA token airdrop<br><br>• 100% of the Book royalties split equally between the holders",
    },
    {
      question: "What is the supply?",
      answer: "<br>Book of Resurrection: 160<br><br>Sorcerers: 2500",
    },
    {
      question: "What is the mint price?",
      answer:
        "<br>Book of Resurrection:<br><strong>Studious: 140 ADA<br>BookWL & Public: 150 ADA</strong><br><br>Sorcerers:<br><strong>Book Guardian:54 ADA<br>Sorcerer WL: 59 ADA<br>Public: 65 ADA</strong>",
    },
    {
      question: "Why Cardano?",
      answer:
        "<br>• Better scalability: Cardano ensures that it can grow to any size and that transactions can take place quickly, by using a settlement and a computational layer<br><br>• Lower costs and energy usage in comparison to other blockchains like ETH<br><br>• Low gas fees<br><br>• The best community! Community leads the way and that's what we, as a team, stand for!",
    },
    {
      question: ".",
      answer: "EXPONENTIA",
    },
  ];

  return (
    <div id="faq" className="like">
      <div className="container">
        <div className="content">
          <h2 className="title">FAQ</h2>
          {faqItems.map((item, index) => (
            <div
              key={index}
              className={`faq-question ${
                index === faqItems.length - 1 ? "last-question" : ""
              }`}
              onClick={() => handleQuestionClick(index)}
            >
              <h3>{item.question}</h3>
              <div
                ref={(element) => (faqAnswersRef.current[index] = element)}
                className={`faq-answer ${
                  index === faqItems.length - 1 ? "last-answer" : ""
                }`}
                dangerouslySetInnerHTML={{ __html: item.answer }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
