import React, { useState } from "react";
export default function Welcome() {
  const [buttonClicked, setButtonClicked] = useState(false);

  const handleClick = () => {
    setButtonClicked(true);
  };
  return (
    <div className="signup">
      <div className="container">
        <div className="content">
          <p className="sub-title">
            <h3>
              {" "}
              <b>Welcome to the Auditorium</b>
            </h3>
          </p>
          <h1 className="title">Zalathor Academy of Sorcery</h1>
          <div
            style={{ fontSize: "26px", color: "#ff3998", fontWeight: "bolder" }}
          >
            {" "}
            Sorcerers SOLD OUT{" "}
          </div>
          <p className="description">
            A multilevel, story-driven project on the Cardano blockchain. Our
            vision is
            <p className="description"></p> to create a diverse ecosystem,bring
            unique utility to our community,
            <p className="description"></p> as well as top quality art.{" "}
            <br></br>
            <br></br>
            <b>Do you have what it takes to enter the Academy?</b>
          </p>
          {buttonClicked ? (
            <a href="https://discord.gg/zalathoracademy">
              <button>Are you ready?</button>
            </a>
          ) : (
            <button onClick={handleClick}>Join the Atheneaum</button>
          )}
        </div>
        {/* <div className="image-container">
          <div className="image">
            <img src={signup} alt="home image" />
          </div>
          <div className="ellipse-container">
            <div className="ellipse pink"></div>
            <div className="ellipse orange"></div> */}
        {/* </div> */}
        {/* </div> */}
      </div>
    </div>
  );
}
