import React from "react";
import Card from "./Card";
import super1 from "../assets/joker.jpg";
import super2 from "../assets/1.jpg";
import super3 from "../assets/8.jpg";
import { BsTwitter } from "react-icons/bs";

export default function SuperRare() {
  const data = [
    {
      image: super1,
      name: "Mentor",
      title: "Co-Founder",
      department: "Full-Stack\nDevelopment",
      tag: "CardanoMentor",
      // time: "Full-Stack Developer",
    },
    {
      image: super2,
      name: "Spell Whisperer",
      title: "Co-Founder",
      department: "Business \nDevelopment",
      tag: "Chris4Ada",
      // time: 2,
    },
    {
      image: super3,
      name: "Necrovox",
      title: "Co-Founder",
      department: "Web-Design",
      tag: "PhilippArtistry",
      // time: 3,
    },
    // {
    //   image: super4,
    //   name: "Gloop name",
    //   title: "Green",
    //   department: 2.99,
    //   tag: 12983,
    //   time: 1,
    // },
  ];
  return (
    <div id="team" className="super-rare">
      <div className="title-container">
        <h2 className="title">The Academy High Mages</h2>
        {/* <p className="description">
          We are very happy to introduce you the 3 core team members of ZAS.
        </p> */}
      </div>
      <div className="cards">
        {data.map(({ image, name, title, department, tag }, index) => (
          <Card
            image={image}
            name={name}
            title={title}
            department={department}
            tag=<a
              href={`https://twitter.com/${tag}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <BsTwitter className="twitter-icon" />
            </a>
            key={index}
          />
        ))}
      </div>
    </div>
  );
}
