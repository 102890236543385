import React from "react";
import roadmap from "../assets/roadmap.jpg";
import roadmap2 from "../assets/roadmap2.jpeg";

export default function RoadmapTEST() {
  return (
    <div className="roadmap">
      <div id="roadmap" className="roadmap-cont">
        <div className="roadmap-content">
          <p className="sub-title1">The Mystic Path (Phase 1)</p>
          <h1 className="title1">
            Bonded together eternally, the 8 Guilds stood together, sustaining
            the balance in the world, each specializing on the 8 different
            schools of witchcraft. For centuries, peace and unity were preserved
            in Grimoire, but as all things in life… it came to an end. <br />
            <br />
            The Zalathor Academy of Sorcery is designed for training and
            teaching aspirants to grow into powerful Sorcerers. The core values
            of every Guild need to be taught and acquired by all the apprentices
            so that they can one day hold the position of the High Mage. <br />
            <br />
            Behold! Phase 1 of our roadmap.
            <br />
            <br /> Your mystical journey begins now...
          </h1>
          {/* <p className="description">
            Do you have what it takes to enter the Academy?
          </p> */}
          {/* <button>Join the Atheneaum</button> */}
        </div>
        <div className="image-container1">
          <div className="image1">
            <img src={roadmap} alt="road1" />
          </div>
          <div className="ellipse-container">
            <div className="ellipse pink"></div>
            <div className="ellipse orange"></div>
          </div>
          {/* /*{" "} */}
        </div>
      </div>
      {/* <div className="roadmap"> */}

      <div id="roadmap2" className="roadmap-cont">
        <div className="roadmap-content">
          <p className="sub-title1">The Mystic Path (Phase 2)</p>
          <h1 className="title1">
            Going through the Mystic Path, you get closer and closer to entering
            the Academy and becoming a powerful Sorcerer. <br />
            <br />
            Phase 2 of our roadmap has been successfully unlocked. <br />
            <br />
            For centuries, peace and unity were preserved in the world but as
            all things in life… it came to an end.
            <br />
            <br /> The Great Battle seems inevitable...
          </h1>
        </div>
        <div className="image-container1">
          <div className="image1">
            <img src={roadmap2} alt="road2" />
          </div>
          <div className="ellipse-container">
            <div className="ellipse pink"></div>
            <div className="ellipse orange"></div>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
}
